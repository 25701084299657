import React, { useEffect, useState } from "react";
import { hasCookie, setCookie } from "cookies-next";
import { Switch } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import TagManager from "react-gtm-module";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);
  const [analyticCookies, setAnalyticCookies] = useState(true);
  const [trackingCookies, setTrackingCookies] = useState(true);
  const [showFunctionalCookies, setShowFunctionalCookies] = useState(false);
  const [showAnalyticsText, setShowAnalyticsText] = useState(false);
  const [showTrackingText, setShowTrackingText] = useState(false);

  useEffect(() => {
    setShowConsent(hasCookie("analyticConsent") && hasCookie("trackingConsent"));
  }, []);

  const saveCookies = () => {
    setShowConsent(true);
    const nextMonth = new Date(Date.now() + 31 * 24 * 60 * 60 * 1000);
    setCookie("analyticConsent", analyticCookies, { expires: nextMonth });
    setCookie("trackingConsent", trackingCookies, { expires: nextMonth });
    const args = {
      gtmId: "GTM-KRX7DR5",
      dataLayer: {
        event: "consent",
        value: {
          add_storage: analyticCookies ? "granted" : "denied",
          analytics_storage: analyticCookies ? "granted" : "denied",
          presonalization_storage: trackingCookies ? "granted" : "denied",
          functionality_storage: trackingCookies ? "granted" : "denied",
          security_storage: trackingCookies ? "granted" : "denied"
        }
      }
    };
    TagManager.dataLayer(args);
  };

  const acceptAllCookies = () => {
    setShowConsent(true);
    const nextMonth = new Date(Date.now() + 31 * 24 * 60 * 60 * 1000);
    setCookie("analyticConsent", true, { expires: nextMonth });
    setCookie("trackingConsent", true, { expires: nextMonth });
    const args = {
      gtmId: "GTM-KRX7DR5",
      dataLayer: {
        event: "consent",
        value: {
          add_storage: "granted",
          analytics_storage: "granted",
          presonalization_storage: "granted",
          functionality_storage: "granted",
          security_storage: "granted"
        }
      }
    };
    TagManager.dataLayer(args);
  };

  if (showConsent) {
    return null;
  }

  return (
    <div className="overscroll-none fixed h-screen z-50 bg-gray-800/75 w-full flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow rounded-lg max-w-2xl mx-auto divide-y">
        <div className="px-4 py-5 sm:p-6 relative">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            🍪 Informatie over cookies
          </h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <p>
              Wij gebruiken cookies op onze website om de gebruikerservaring te verbeteren en te
              personaliseren. Deze cookies worden gebruikt voor onder andere basisfunctionaliteit,
              voorkeuren en instellingen, het analyseren van website gebruik en het tonen van
              gerichte advertenties. Dit helpt ons om een optimale ervaring te bieden.
            </p>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6 relative">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Functionele cookies</h3>
          <div className="absolute top-0 right-0 px-4 py-5 sm:p-6 ">
            <p className="text-sm">
              Altijd actief{" "}
              {showFunctionalCookies ? (
                <ChevronUpIcon
                  onClick={() => {
                    setShowFunctionalCookies(false);
                  }}
                  className="h-6 w-6 ml-3 inline"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => {
                    setShowFunctionalCookies(true);
                  }}
                  className="h-6 w-6 ml-3 inline"
                  aria-hidden="true"
                />
              )}
            </p>
          </div>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div
              className={` ${
                showFunctionalCookies ? "inline-flex" : "hidden"
              } max-w-xl text-sm text-gray-500`}
            >
              <p>
                Functionele cookes zijn nodig voor het goed functioneren van de website. Ze zorgen
                dat de website instellingen kan opslaan, zoals je taalvoorkeur, login gegevens en
                thema. Zonder deze cookies zou de website niet goed werken.
              </p>
            </div>
          </div>
        </div>
        <Switch.Group as="div" className="px-4 py-5 sm:p-6 relative">
          <Switch.Label as="h3" className="text-base font-semibold leading-6 text-gray-900" passive>
            Analytische cookies
          </Switch.Label>
          <div className="absolute top-0 right-0 px-4 py-5 sm:p-6 ">
            <Switch
              checked={analyticCookies}
              onChange={setAnalyticCookies}
              className={` ${
                analyticCookies ? "bg-red-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={` ${
                  analyticCookies ? "translate-x-5" : "translate-x-0"
                } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            {showAnalyticsText ? (
              <ChevronUpIcon
                onClick={() => {
                  setShowAnalyticsText(false);
                }}
                className="h-6 w-6 mb-3 ml-3 inline"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                onClick={() => {
                  setShowAnalyticsText(true);
                }}
                className="h-6 w-6 mb-3 ml-3 inline"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div
              className={` ${
                showAnalyticsText ? "inline-flex" : "hidden"
              } max-w-xl text-sm text-gray-500`}
            >
              <Switch.Description>
                Analytische cookies zijn cookies die informatie verzamelen over hoe onze bezoekers
                de website gebruiken. Deze informatie helpt ons om de website te verbeteren. De
                informatie is anoniem en wordt niet gebruikt voor andere doeleinden.
              </Switch.Description>
            </div>
          </div>
        </Switch.Group>
        <Switch.Group as="div" className="px-4 py-5 sm:p-6 relative">
          <Switch.Label as="h3" className="text-base font-semibold leading-6 text-gray-900" passive>
            Tracking cookies
          </Switch.Label>
          <div className="absolute top-0 right-0 px-4 py-5 sm:p-6 ">
            <Switch
              checked={trackingCookies}
              onChange={setTrackingCookies}
              className={` ${
                trackingCookies ? "bg-red-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={` ${
                  trackingCookies ? "translate-x-5" : "translate-x-0"
                } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            {showTrackingText ? (
              <ChevronUpIcon
                onClick={() => {
                  setShowTrackingText(false);
                }}
                className="h-6 w-6 mb-3 ml-3 inline"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                onClick={() => {
                  setShowTrackingText(true);
                }}
                className="h-6 w-6 mb-3 ml-3 inline"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div
              className={` ${
                showTrackingText ? "inline-flex" : "hidden"
              } max-w-xl text-sm text-gray-500`}
            >
              <Switch.Description>
                Tracking cookies worden gebruikt om surfgedrag van gebruikers te volgen. Hiermee
                kunnen wij gerichte advertenties tonen op basis van jouw interesses.
              </Switch.Description>
            </div>
          </div>
        </Switch.Group>
        <div className="px-4 space-y-2 py-4">
          <button
            onClick={acceptAllCookies}
            className="
              px-5 py-3 rounded-xl shadow w-full
              bg-red-600 font-medium text-white hover:bg-red-700 text-sm"
          >
            Alles accepteren
          </button>
          <button
            onClick={saveCookies}
            className="
              px-5 py-3 rounded-xl shadow w-full rounded-xl shadow
              bg-gray-200 font-medium hover:bg-gray-300 text-sm"
          >
            Opslaan
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
