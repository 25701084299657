import React from "react";
import Link from "next/link";

const Footer = () => {
  return (
    <footer aria-labelledby="footer-heading" className="bg-[#1C0000]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-4 sm:py-6 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center justify-center text-sm text-gray-100">
            <Link href="/privacy">Privacy- en gebruikersvoorwaarden</Link>
            <p className="ml-3 border-l border-gray-200 pl-3">Cadeautje.me</p>
          </div>
          <p className="mt-2 text-sm text-gray-100 text-center sm:mt-0">
            &copy; 2024 Cadeautje.me - Alle rechten voorbehouden.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
